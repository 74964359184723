import { getCookie, nginxDecodeUserId } from '~/helpers/cookieHelpers';

export default ({ app }, inject) => {
  if (process.client) {
    const sendEvent = async(ea, ec, el) => {
      const { host } = window.location;
      const pageType = () => window.$nuxt?.$store?.state?.bucket?.page_type || window.pageType;

      const visitorIdCookie = getCookie('uid');
      if (!visitorIdCookie) {
        console.log('[FLAGSHIP GTM] VisitorId is not defined');
        return;
      }

      const visitorId = nginxDecodeUserId(visitorIdCookie);
      const flagshipEnvironmentId = getCookie('xpe');
      const flagshipEventsAPIEndpoint = app.$config.FLAGSHIP_EVENTS_API_BASE_URL;

      // Prepare the payload for the event
      const payload = {
        cid: flagshipEnvironmentId,
        vid: visitorId,
        dl: document.location.href,
        ds: 'APP',
        ev: 1,
        t: 'EVENT',
        ea,
        ec,
        el,
      };

      try {
        const response = await fetch(
          `${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType()}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          },
        );

        if (!response.ok) {
          console.error('[FLAGSHIP GTM] Event could not be sent via Fetch');
        }
      } catch (error) {
        console.error('[FLAGSHIP GTM] Error sending event via Fetch:', error);
      }
    };

    // Define a function to track interaction with dynamic event details
    const trackInteraction = (
      eventName = 'nonBounceInteraction',
      action = 'Action Tracking',
      experimentName = 'airport-nuxt3',
    ) => {
      // Send event to Flagship with dynamic parameters
      sendEvent(eventName, action, experimentName);
    };

    // Inject the function into the Nuxt context
    inject('trackInteraction', trackInteraction);

    const trackInteractionWrapper = (event) => {
      // Ignore clicks on elements with specific IDs
      const { target } = event;
      if (
        target.closest('#CybotCookiebotDialog')
        || target.closest('#CybotCookiebotDialogBodyUnderlay')
      ) {
        return;
      }
      trackInteraction();
      document.body.removeEventListener('click', trackInteractionWrapper);
    };

    document.body.addEventListener('click', trackInteractionWrapper);
  }
};
