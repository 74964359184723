var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"bg-blue-600 relative leading-normal"},[_c('ReferralCashback'),_vm._v(" "),_c('div',{ref:"highlightElement",staticClass:"bg-blue-600"},[_c('section',{class:[
        'top-menu',
        {
          'top-menu--collapse': _vm.collapse,
          'top-menu--scrolled': _vm.scrollTop,
          'h-full': !_vm.collapse,
          flex: !_vm.collapse,
        },
      ]},[_c('div',{class:[
          'top-menu__logo-wrapper',
          {
            'py-1': _vm.scrollTop && _vm.collapse,
            'py-4': !_vm.collapse || !_vm.scrollTop,
          },
        ]},[_c('div',{staticClass:"top-menu__logo-hyperlink__wrapper",attrs:{"data-test":"parkos-logo"}},[_c('a',{staticClass:"top-menu__logo-hyperlink",attrs:{"href":"/"},on:{"click":_vm.clear}},[_c('Logo')],1),_vm._v(" "),_c('PartnerLogo')],1),_vm._v(" "),_c('div',{staticClass:"top-menu__toggle-menu-wrapper"},[_c('a',{attrs:{"href":"/login/"},on:{"click":_vm.clear}},[_c('IconUser',{staticClass:"top-menu__toggle-menu__user-icon",attrs:{"data-test":"menu-login"}})],1),_vm._v(" "),_c('ToggleMenu',{staticClass:"top-menu__toggle-menu",attrs:{"open":!_vm.collapse},on:{"toggled":_vm.toggleMenu}})],1)]),_vm._v(" "),_c('nav',{class:[
          'header-nav',
          {
            'header-nav--collapse': _vm.collapse,
            'header-nav--mega': _vm.megaMenuOpen,
          },
        ]},[_c('div',{ref:"scroller",class:[
            'header-nav__scroller',
            {
              'overflow-y-scroll': !_vm.isSearchActive && !_vm.languagesExpanded,
              relative: _vm.isSearchActive || _vm.languagesExpanded,
            },
          ]},[_c('ul',{staticClass:"header-nav__menu md:mx-auto min-h-max",class:{
              'header-nav__menu--toggled': _vm.megaMenuOpen,
              'hidden md:flex': _vm.languagesExpanded,
            }},[_c('div',{staticClass:"flex flex-col sm:hidden"},[_c('li',{staticClass:"header-nav__menu__item",class:{ 'header-nav__menu__item--sub': _vm.megaMenuOpen }},[_c('div',{staticClass:"text-black md:text-white w-full md:w-auto"},[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('MegaMenu',{attrs:{"open":_vm.megaMenuOpen,"expression":_vm.searchExpression},on:{"toggled":_vm.megaMenuToggled}})],1)],1)]),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.zenDeskLangCode),expression:"zenDeskLangCode"}],staticClass:"header-nav__menu__item"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":`https://parkos.zendesk.com/hc/${_vm.zenDeskLangCode}`,"rel":"nofollow"},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"},[_vm._v("\n                    "+_vm._s(_vm.$i18n("general.customer-service"))+"\n                  ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)]),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.aboutPageLink),expression:"aboutPageLink"}],staticClass:"header-nav__menu__item"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":_vm.aboutPageLink},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"},[_vm._v("\n                    "+_vm._s(_vm.aboutPageTitle)+"\n                  ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)])])]),_vm._v(" "),_c('ul',{staticClass:"header-nav__menu header-nav__menu--abs",class:{
              'header-nav__menu--sub': _vm.languagesExpanded,
              'hidden md:flex': _vm.megaMenuOpen,
            }},[_c('li',{staticClass:"header-nav__menu__item header-nav__menu__item--login"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":"/login/"},on:{"click":_vm.clear}},[_c('div',{staticClass:"header-nav__menu__item__link--text",attrs:{"data-test":"menu-login"}},[_vm._v("\n                  "+_vm._s(_vm.$i18n('templates.header-login'))+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"header-nav__menu__item__link--icon"})],1)]),_vm._v(" "),(_vm.faqMeta)?_c('li',{staticClass:"header-nav__menu__item pr-0 header-nav__menu__item--meta"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":_vm.faqPageLink},on:{"click":_vm.clear}},[_c('div',{staticClass:"header-nav__menu__item__link--text"},[_vm._v("\n                  "+_vm._s(_vm.faqPageTitle)+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"header-nav__menu__item__link--icon"})],1)]):_vm._e(),_vm._v(" "),_c('li',{staticClass:"header-nav__menu__item pr-0",class:{
                'header-nav__menu__item--toggled': _vm.languagesExpanded,
              }},[_c('MobileLanguages',{staticClass:"block md:hidden",attrs:{"expanded":_vm.languagesExpanded},on:{"toggled":_vm.languagesToggled}}),_vm._v(" "),_c('Languages',{directives:[{name:"show",rawName:"v-show",value:(_vm.$device.isDesktop),expression:"$device.isDesktop"}],staticClass:"font-sans hidden md:flex items-center"})],1)])])])])]),_vm._v(" "),_c('div',{staticClass:"xs:container pt-83px md:pt-12 pb-18 px-5 md:px-10 md:py-20 relative"},[_c('LazyHydrate',{class:{
        'hidden md:block': _vm.$experimentVariant('reduce-font-size') === 'b',
      },attrs:{"when-idle":"","when-visible":""}},[_c('WorldMapParkos',{staticClass:"worldmap pointer-events-none max-w-3xl absolute -right-64 top-1/2 -mt-12"})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('MetaReviews')],1),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[_c('h1',{staticClass:"text-lg md:text-3xl airport-title-main flex mt-8 mb-0 md:mb-2.5 md:mt-3 text-white font-cocogoose relative w-7/10 sm:w-auto",attrs:{"data-test":"airport-name"}},[_vm._v("\n        "+_vm._s(_vm.airport.maintitle)+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"flex md:flex-row flex-col"},[(_vm.avgPricePerDay)?_c('div',{staticClass:"hidden md:block text-white text-lg font-sans",attrs:{"data-test":"avg-price-day"}},[_vm._v("\n        "+_vm._s(_vm.$i18n('templates.from-x-euro-day', { amount: _vm.avgPricePerDay }))+"\n      ")]):_vm._e(),_vm._v(" "),(!_vm.showAirportsDropdown)?_c('div',{staticClass:"mt-px cursor-pointer underline text-white text-md md:text-base",class:{ 'md:ml-8': _vm.avgPricePerDay },attrs:{"id":"locations_datepicker_change_airport_link","data-test":"airport-change"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changeAirport.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm.$i18n('general.change-airport'))+"\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex flex-col md:block"},[_c('div',{staticClass:"inline-flex flex-col relative md:pr-16"},[_c('QuickBookBadge'),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":"","when-visible":""}},[_c('ImprovedSearch',{ref:"searchForm",staticClass:"mt-7",attrs:{"show-airports":_vm.showAirportsDropdown}})],1)],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col xs:flex-row place-content-between mt-7 relative z-10",class:{
          'max-w-4xl': !_vm.showAirportsDropdown,
          'max-w-6xl': _vm.showAirportsDropdown,
        }},[_c('div',{staticClass:"order-2 xs:order-1 header-checkmarks flex flex-col md:flex-row mt-5 xs:mt-0 text-[14px] font-normal",attrs:{"data-test":"header-ups"}},[_c('div',{staticClass:"my-2 md:my-0 font-sans text-md md:mr-10 text-white flex items-center",attrs:{"data-test":"header-ups-best-deal"}},[_c('IconCheckmark',{staticClass:"icon-checkmark"}),_vm._v(" "),_c('div',{staticClass:"flex-1"},[_vm._v("\n              "+_vm._s(_vm.$i18n('templates.header-usp-1'))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"my-2 md:my-0 font-sans text-md md:mr-10 text-white flex items-center",attrs:{"data-test":"header-ups-number-customers"}},[_c('IconCheckmark',{staticClass:"icon-checkmark"}),_vm._v(" "),_c('div',{staticClass:"flex-1"},[_vm._v("\n              "+_vm._s(_vm.$i18n('templates.header-usp-2'))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"my-2 md:my-0 font-sans text-md text-white flex items-center",attrs:{"data-test":"header-ups-free-cancel"}},[_c('IconCheckmark',{staticClass:"icon-checkmark"}),_vm._v(" "),_c('div',{staticClass:"flex-1"},[_vm._v("\n              "+_vm._s(_vm.$i18n('templates.header-usp-3'))+"\n            ")])],1)])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }