<template>
  <div class="relative support-wrapper text-white py-10 xs:py-20">
    <div class="xs:container px-5 sm:px-10">
      <div class="sm:w-6/12">
        <div class="text-base md:text-xl font-cocogoose">
          {{ $i18n('customer.we-support') }}
        </div>
        <div class="my-7 text-[14px] xs:text-base">
          {{ $i18n('customer.our-experts-description') }}
        </div>

        <a
          id="locations_contact_us_button"
          class="text-md md:text-15 hover:bg-blaze-orange-500 hover:no-underline font-cocogoose flex max-w-max px-6 bg-orange-500 text-white py-3 rounded-md text-center cursor-pointer whitespace-nowrap"
          data-test="contact-support-button"
          :href="`https://parkos.zendesk.com/hc/${zenDeskLangCode}`"
        >
          {{ $i18n('customer.contact-us') }}
        </a>
      </div>
    </div>
    <div
      class="support-wrapper__image -z-10 transform absolute inset-0 overflow-hidden"
    >
      <img
        src="https://assets.parkos.com/assets/img/footer-bg-new-wide.jpg"
        loading="lazy"
        alt=""
        class="object-cover object-left-top md:object-top absolute inset-0 max-w-none w-full h-full"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$store.state.language;
    },
    zenDeskLangCode() {
      if (this.language && this.language.lang) {
        const langCode = this.language.lang;
        if (langCode === 'en-eu') {
          return 'en-150';
        }
        return langCode;
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
.support-wrapper {
  background-color: rgba(14, 16, 23, 0.6);

  &__image {
    transform: scaleX(-1);
  }
}
</style>
