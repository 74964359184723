<template>
  <svg
    width="115"
    height="115"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="badge md:hidden"
    data-test="header-badge"
  >
    <defs>
      <rect id="a" x="0" y="0" width="115" height="115" rx="79" />
      <mask
        id="b"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="115"
        height="115"
        fill="#fff"
      >
        <use xlink:href="#a" />
      </mask>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <rect
        fill="#123878"
        x="6"
        y="6"
        width="102"
        height="102"
        rx="68"
      />
      <use
        stroke="#123878"
        mask="url(#b)"
        stroke-width="8"
        stroke-dasharray="2"
        xlink:href="#a"
      />
      <text class="text" :font-size="fontSizeLower">
        <tspan x="16" y="70">{{ $i18n('general.quick-to-book') }}</tspan>
      </text>
      <text class="text" font-size="22">
        <tspan x="24" y="50">3 MIN</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  computed: {
    fontSizeLower() {
      switch (this.$store.state.language.lang) {
        case 'sv-se':
          return 10;
        case 'pl':
          return 14;
        case 'da-dk':
          return 15;
        case 'it':
        case 'it-ch':
          return 11;
        case 'de':
        case 'de-at':
        case 'de-ch':
          return 13;
        default:
          return 12;
      }
    },
  },
};
</script>

<style scoped>
.text {
  font-family: 'Arial Black';
  font-weight: 800;
  fill: #fff;
}

.badge {
  transform: rotate(-20deg) translate(44px, -55px);
  position: absolute;
  right: 0;
  top: 0;
}
</style>
