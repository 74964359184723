<template>
  <Dropdown
    v-if="languagesFiltered && languagesFiltered.length > 1"
    key="languages-dropdown"
    class="languages-dropdown"
    @toggled="handleToggle"
  >
    <template #button>
      <div class="languages-dropdown__selected" data-test="menu-languages">
        <Flag :lang="language.lang" />
        {{ language.lang.split('-')[0] | uppercase }}
        <span class="caret hidden sm:block ml-1" />
        <IconCaretRight class="text-black-alt-500 ml-auto sm:hidden" />
      </div>
    </template>
    <template #content>
      <ul class="languages-dropdown__menu">
        <li
          v-for="item in languagesFiltered"
          v-show="item.lang !== language.lang"
          :key="`lang-${item.lang}`"
        >
          <a
            :href="`${airportDataLanguages[item.lang].url}`"
            class="languages-dropdown__menu-item"
          >
            <Flag :lang="item.lang" :lazy-loading="true" />
            <span>{{ item.native_name }}</span>
          </a>
        </li>
      </ul>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '~/components/header/Dropdown.vue';
import Flag from '~/components/icons/Flag.vue';
import IconCaretRight from '~/components/icons/IconCaretRight.vue';

export default {
  filters: {
    uppercase: (value) => value.toUpperCase(),
  },

  components: {
    Dropdown,
    Flag,
    IconCaretRight,
  },

  computed: {
    languages() {
      return this.$store.state.languages
        .map((a) => a)
        .sort((a, b) => (a.native_name > b.native_name ? 1 : -1));
    },
    language() {
      return this.$store.state.language;
    },
    airportDataLanguages() {
      return this.$store.state.airportDataContentLanguages;
    },
    languagesFiltered() {
      const keys = Object.keys(this.airportDataLanguages);
      return this.languages.filter((l) => keys.includes(l.lang));
    },
  },
  methods: {
    handleToggle(expanded) {
      this.$emit('toggled', expanded);
    },
  },
};
</script>
<style lang="scss">
.languages-dropdown {
  @apply border-b-0 pr-0 -mr-6;

  &__selected {
    @apply flex items-center sm:w-auto w-full;
  }

  &__menu {
    @apply sm:py-2 sm:-ml-2 w-52;

    &-item {
      @apply flex px-6 sm:px-4 py-3 sm:border-none border-b border-athens sm:py-1 text-base text-gray-600 whitespace-nowrap sm:text-black hover:text-black hover:no-underline sm:hover:bg-gray-200 items-center;
    }
  }
}
</style>